<template>
  <b-col>
    <b-row cols="12">
      <b-button variant="success" @click="newShipment">New Shipment</b-button>
<!--      <b-input @change="loadShipments" placeholder="filter text"></b-input>-->
    </b-row>
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th>Date</th>
        <th>Recipient</th>
        <th>Contents</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="shipment in shipments" :key="shipment.id">
        <td>{{shipment.date_of_request}}</td>
        <td>{{shipment.recipient.name}}</td>
        <td>{{shipment.content_info.contents}}</td>
        <td>
          <b-button-group style="float: right">
            <b-button @click="$router.push('edit-shipment/'+shipment.id)" variant="warning" title="Edit Shipment"><b-icon-pencil-square></b-icon-pencil-square></b-button>
            <b-button @click="downloadShipment(shipment)" variant="success" title="Download PDF"><b-icon-download></b-icon-download></b-button>
            <b-button v-b-modal="'delete-confirmation-'+shipment.id" variant="danger" title="Delete Shipment"><b-icon-trash></b-icon-trash></b-button>
            <b-modal :id="'delete-confirmation-'+shipment.id" ok-title="Delete" ok-variant='danger'
                     title="Delete Confirmation" @ok="deleteShipment(shipment)">
              Are you sure that you wish to delete the following shipment record? '{{shipment.id}}'
              <hr>
            </b-modal>
          </b-button-group>
        </td>
      </tr>
      </tbody>
    </table>

  </b-col>
</template>

<script>
import http from "../http-common"
import contentDisposition from "@/content-disposition-parser";
import FileDownload from "js-file-download";

export default {
  name: "ShipmentHome",
  props: [
      'loggedIn',
  ],
  data: function() {
    return {
      shipments: [],
      n_shipments: 0,
      current_page: 1,
      per_page: 20,
      filter_text: "",
      recipients: [],
    }
  },
  methods: {
    loadShipments(page) {
      let loader = this.$loading.show();
      if (page === undefined) {
        page = 1;
      }
      http.get('/shipment/', {
        params: {
          text: this.filter_text,
          offset: (this.current_page - 1) * this.per_page,
          limit: this.per_page,
        }
      })
      .then((resp) => {
        this.shipments = resp.data.shipments;
        this.n_shipments = resp.data.n_shipments;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide();
      });
    },
    newShipment() {
      let loader = this.$loading.show();
      http.post('/shipment/')
          .then((resp) => {
            let new_id = resp.data.id;
            this.$router.push('/edit-shipment/'+new_id);
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
          .finally(() => {
            loader.hide();
          });
    },
    downloadShipment(shipment) {
      let loader = this.$loading.show()
      http.get("/shipment/download/"+shipment.id,
          {responseType: 'blob'})
          .then((resp) => {
            let cd = contentDisposition.parse(resp.request.getResponseHeader('Content-Disposition'));
            FileDownload(resp.data, cd.parameters.filename);
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
          .finally(() => {
            loader.hide()
          })
    },
    deleteShipment(shipment) {
      http.delete("/shipment/"+shipment.id)
          .catch((err) => {
            http.std_error_handler(this, err);
          })
          .finally(() => {
            this.loadShipments(1);
          })
    }
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  mounted() {
    this.loadShipments();
  },
}
</script>

<style scoped>

</style>